import React from "react";
import Uploadimg from "./uploadimg";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PicGallry = () => {

  const [slider, setSlider] = useState();
  const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [urldata, setData] = useState('');
  const [selectValue, setselectValue] = useState('');

  // Callback function to update data
  const updateData = newData => {
    setData(newData);

  };

  useEffect(() => {
    const url = 'https://schoolapi.jyotipedia.com/api/gallary';
    showSlider(url);
  }, [comUpdate]);

  const showSlider = async (url) => {

    const res = await fetch(url);
    const data = await res.json();
    console.log(data.gallary_data);
    setSlider(data.gallary_data);
  }

  const deleteData = async (id) => {

    const url = "https://schoolapi.jyotipedia.com/api/gallary/delete/" + id;

    const res = await fetch(url, {
      method: "delete"
    })

    const data = await res;
    if (data.status == "200") {
      toast.success("Data Deleted Successfull", {
        position: "top-right",
        theme: "colored",
      });
    }
    else {
      toast.error("Process Failed", {
        position: "top-right",
        theme: "colored",
      });
    }
    forceUpdate()
  }

  const getImg = (url) => {
    const parts = url.split("\/");
    const newurl = "https://schoolapi.jyotipedia.com/image/" + parts[1];
    return newurl;
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setselectValue(value);
  }

  const AddData = async () => {
    const url = "https://schoolapi.jyotipedia.com/api/gallary/add";

    const formdata = {
      img: urldata,
      tag: selectValue
    }
    try {

      const res = await fetch(url, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formdata)
      });

      const res_data = res.status;
      if (res_data == "200") {
        toast.success("Pic Uploded Successfull", {
          position: "top-right",
          theme: "colored",
        });
        forceUpdate();
      }
      else {
        toast.error("Pic Uploding Process Failed from Backend", {
          position: "top-right",
          theme: "colored",
        });
      }
    }
    catch (error) {
      toast.error("Pic Uploding Process Failed from Frontend" + error, {
        position: "top-right",
        theme: "colored",
      });
    }
  }

  return (
    <main id="main" className="main">


      <div className="pagetitle">
        <h1>Pic Gallary Tables</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item">Pic Gallary Table</li>

          </ol>
        </nav>
      </div>


      <section className="section">
        < div className="row col-lg-12 col-md-12 ">
          <div className="row col-lg-7 col-md-7 ">


            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Pic Gallary Data</h5>


                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">img</th>

                      <th scope="col">Img Tag</th>
                      <th scope="col">Add Date</th>

                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {

                      slider && slider.map((slide, i) => (
                        <tr key={slide._id}>
                          <th scope="row">{i + 1}</th>
                          <td><img src={getImg(slide.img)} style={{ "width": "100px", "height": "80px" }} /></td>
                          <td>{slide.tag}</td>
                          <td>{(slide.AddDate).split('T')[0]}</td>
                          
                          <td><button class="btn btn-primary w-100" onClick={() => { deleteData(slide._id) }} type="submit">Delete</button></td>
                        </tr>
                      ))}

                  </tbody>
                </table>

              </div>
            </div>
          </div>

          <div className="row col-lg-4 col-md-4 " style={{ "margin-left": "50px" }}>

            <div class="form-floating mb-3">
              <h4>Add Pic In Gallary</h4>

              <div class="form-floating">
                <input type="email" class="form-control" id="floatingEmail" name="heading" placeholder="Your Email" value={urldata} />
                <label for="floatingEmail">Img Path</label>
              </div>
              <br></br>
              <select class="form-select" id="floatingSelect" aria-label="State" name="pageName" value={selectValue} onChange={handleSelectChange}>
                <option selected="" value="Other">Other</option>
                <option value="Cadet">Cadet cops</option>
                <option value="Shooting">Shooting Range </option>
                <option value="Education">Education</option>
                <option value="Video">Video Tour</option>
              </select>

              <br></br>
              <button class="btn btn-primary w-100" onClick={() => { AddData() }} type="submit">Add Data</button>
            </div>

            <Uploadimg onUpdate={updateData} />



          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );

}

export default PicGallry;